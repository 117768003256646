import React from 'react';
import jumbotronImg from '../../assets/img/jumbotron-image.png';
import { Button } from '../common/buttons/Button';

import './Jumbotron.css';

export const Jumbotron = () => {
    const style = {
        marginTop: '8px',
    };

    return (
        <div
            className='jumbotron-section row'
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <div className='jumbotron-section__text col mb-sm-4'>
                <h1>
                    With our <span className='selected-text'>Very own</span>
                    <br /> BoozeMoon Crypto Beer
                </h1>
                <h2>Let’s go to The Moon and Get Drunk </h2>
                <p className='mb-sm-4'>
                    BoozeMoon will present the first crypto beer shortly. Brewed
                    in Germany this beer will not only impress crypto
                    enthusiasts but also beer gourmets. Support us and help us
                    and become part of an awesome community of crypto and booze
                    supporters
                </p>
                <div
                    className='jumbotron-section__text__buttons justify-content-sm-center'
                    style={{ display: 'flex' }}
                >
                    <a
                        href='https://pancakeswap.finance/swap?outputCurrency=0x36b50a5ac2b90c7652d4ac17181aa63b7ceb2fea&inputCurrency=BNB'
                        target='_blank'
                        className='jumbotron__button-link'
                    >
                        <Button btnText='buy now' style={style} />
                    </a>

                    <a
                        href='https://poocoin.app/tokens/0x36b50a5ac2b90c7652d4ac17181aa63b7ceb2fea'
                        target='_blank'
                        className='jumbotron__button-link'
                    >
                        <Button btnText='chart' style={style} />
                    </a>
                </div>
            </div>
            <div
                className='jumbotron-section__img col d-none d-sm-block'
                style={{ textAlign: 'center' }}
            >
                <img src={jumbotronImg} alt='jumbotronImg' />
            </div>
        </div>
    );
};
