import React, { Fragment, Component, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGa from 'react-ga';
import { getListTweets } from '../../store/tweet/tweetActions';
import AppHeader from '../header/Header';
import { PageContainer } from '../../styles/globals';
import { Heading } from '../heading/Heading';
import { Jumbotron } from '../jumbotron/Jumbotron';
import { Statistics } from '../statistics/Statistics';
import { Chart } from '../chart/Chart';
import { Whitepaper } from '../whitepaper/Whitepaper';
import { Footer } from '../footer/Footer';
import { Navbar } from '../navbar/Navbar';
import { Invest } from '../invest/Invest';
import { Timezone } from '../timezone/Timezone';
import './Home.css';

class Home extends Component {
    componentDidMount() {
        ReactGa.initialize('G-DG1ZDX756D');
        //to report page view
        ReactGa.pageview('/');
    }

    render() {
        return (
            <Fragment>
                <Heading />
                <PageContainer>
                    <div className='test-section'>
                        <div className='container'>
                            <Navbar />
                            <Jumbotron />
                            {/* <Statistics /> */}
                        </div>
                    </div>

                    <div className='container'>
                        <Invest />
                    </div>

                    <div className='container'>
                        <Chart />
                    </div>

                    <div className='timezone-section'>
                        <Timezone />
                    </div>

                    <div className='white-section'>
                        <div className='container'>
                            <Whitepaper />
                        </div>
                        <footer className='footer'>
                            <Footer />
                        </footer>
                    </div>
                </PageContainer>
            </Fragment>
        );
    }
}

export default connect(null, { getListTweets })(Home);
