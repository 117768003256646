import React from 'react';
import navLogoImg from '../../assets/img/main_logo.png';
import { Link as ScrollLink } from 'react-scroll';
import './Navbar.css';

export const Navbar = () => {
    return (
        <nav className='navbar navbar-dark navbar-expand-md '>
            <div className='navbar__logo'>
                <a className='navbar-brand' href='#'>
                    <img src={navLogoImg} height='120' alt='logo' />
                </a>
            </div>
            <button
                className='navbar-toggler custom-toggler'
                data-bs-toggle='collapse'
                data-bs-target='#navbar'
            >
                <span className='navbar-toggler-icon'></span>
            </button>
            <div className='navbar-collapse collapse navbar-menu' id='navbar'>
                <ul className='navbar-nav'>
                    <li className='nav-item active'>
                        <ScrollLink
                            className='nav-link'
                            to='home'
                            smooth={true}
                            duration={500}
                        >
                            Home <span className='sr-only'>(current)</span>
                        </ScrollLink>
                    </li>
                    <li className='nav-item'>
                        <ScrollLink
                            className='nav-link'
                            to='invest'
                            smooth={true}
                            duration={500}
                        >
                            Tokenomics
                        </ScrollLink>
                    </li>
                    <li className='nav-item'>
                        <ScrollLink
                            className='nav-link'
                            to='roadmap'
                            smooth={true}
                            duration={500}
                        >
                            Roadmap
                        </ScrollLink>
                    </li>
                    <li className='nav-item'>
                        <ScrollLink
                            className='nav-link '
                            to='whitepaper'
                            smooth={true}
                            duration={500}
                        >
                            Whitepaper
                        </ScrollLink>
                    </li>
                    <li className='nav-item'>
                        <ScrollLink
                            className='nav-link '
                            to='whitepaper'
                            smooth={true}
                            duration={500}
                        >
                            Audit
                        </ScrollLink>
                    </li>
                    <li className='nav-item'>
                        <ScrollLink
                            className='nav-link '
                            to='whitepaper'
                            offset={500}
                            smooth={true}
                            duration={500}
                        >
                            How To Buy
                        </ScrollLink>
                    </li>
                    <li className='nav-item'>
                        <a
                            href='https://boozemoon.myshopify.com/'
                            target='_blank'
                            className='nav-link'
                        >
                            Shop
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};
