import styled from 'styled-components';

export const ListWrapper = styled.ul`
    list-style: none;
`;
export const PageContainer = styled.div`
    margin: 0rem auto 0 auto;
    //max-width: 500px;
    background-color: #404040;
    overflow-x: hidden
`;
export const AppNavBar = styled.ul`
    flex-direction: row;
`;
export const AppNavBarItem = styled.li`
    margin-left: 10px;
`;
export const ListItemWrap = styled.li`
    margin-bottom: 30px;
`;
export const PostContent = styled.div`
    margin-top: 25px;
`;
