import React from 'react';
import chartPic from '../../assets/img/chart.png';
import './Chart.css';

export const Chart = () => {
    return (
        <div className='chart-section'>
            <div className='chart-section__title mb-sm-5'>
                <h1>Token Allocation</h1>
            </div>
            <div className='chart-section__content row'>
                <div className='chart-cards col col-sm-12 col-md-4'>
                    <div className='chart-cards__item orange'>
                        <p>PRE SALE: 25%</p>
                    </div>
                    <div className='chart-cards__item white'>
                        <p>pancakeswap liquidity: 23%</p>
                    </div>
                    <div className='chart-cards__item green'>
                        <p>burned: 38.5%</p>
                    </div>
                </div>

                <div className='chart-section__img col col-sm-12 col-md-4 text-center mb-sm-5 mt-sm-5'>
                    <img src={chartPic} alt='chart' />
                </div>
                <div className='chart-cards col col-sm-12 col-md-4'>
                    <div className='chart-cards__item lightblue'>
                        <p>DEVELOPERS: 5%</p>
                    </div>
                    <div className='chart-cards__item blue'>
                        <p>MARKETING AND PRESALE: 4%</p>
                    </div>
                    <div className='chart-cards__item purple'>
                        <p>ads budget: 4.5% </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
