import React from 'react';
import { Element as ScrollElement } from 'react-scroll';
import {
    faCreditCard,
    faGem,
    faShoppingCart,
    faFire,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import liImg from '../../assets/img/li-item.png';

import './Invest.css';

export const Invest = () => {
    return (
        <ScrollElement name='invest'>
            <div className='invest-section row'>
                <div className='invest-section__safe col-12 col-xs-12 col-sm-6 mb-sm-5'>
                    <h1 className='text-sm-center'>We are Safe</h1>
                    <p>
                        Help us to get the booze going be investing in BoozeMoon
                        your one and only beer token!!
                    </p>
                    <ul className='invest-section__safe-list mb-sm-5 mt-sm-5'>
                        <img
                            src={liImg}
                            alt='li-img'
                            className='safe-list__image'
                        />
                        <li className='invest-section__safe__list-item'>
                            Ownership Renounced
                        </li>
                        <img
                            src={liImg}
                            alt='li-img'
                            className='safe-list__image'
                        />
                        <li className='invest-section__safe__list-item'>
                            Unmintable
                        </li>
                        <img
                            src={liImg}
                            alt='li-img'
                            className='safe-list__image'
                        />
                        <li className='invest-section__safe__list-item'>
                            Fully Audited Contract
                        </li>
                        <img
                            src={liImg}
                            alt='li-img'
                            className='safe-list__image'
                        />
                        <li className='invest-section__safe__list-item'>
                            Marketing and developer tokens
                        </li>
                    </ul>
                    <p>
                        5% Ads Tokens Vested for 5 days, Developer tokens vested
                        10 days. If a developer sells before a six months period
                        is over he receives a 30% penalty fee.!
                    </p>
                </div>
                <div className='hard-facts col-12 col-xs-12 col-sm-6'>
                    <h1 className='text-sm-center'>The Hard Facts</h1>
                    <div className='hard-facts__card justify-content-sm-center'>
                        <div className='hard-facts__card__item col col-sm-6'>
                            <div className='card__item card-border-p1'>
                                <FontAwesomeIcon
                                    icon={faCreditCard}
                                    className='card__item-icon'
                                />
                                <span className='card__item-percent p-move'>
                                    6%
                                </span>
                                <p>Tax on Transaction</p>
                                <span style={{ display: 'block', width: '100%'}}>6% Tax on Sales</span>
                                <span>0% Tax on Buys</span>
                            </div>
                        </div>
                        <div className='hard-facts__card__item col col-sm-6'>
                            <div className='card__item card-border-p2'>
                                <FontAwesomeIcon
                                    icon={faFire}
                                    className='card__item-icon'
                                />
                                <span className='card__item-percent p-move'>
                                    6 %{' '}
                                </span>
                                <p>Auto Burned</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollElement>
    );
};
