import React from 'react';
import whitepaper from '../../assets/img/whitepaper.png';
import { Button } from '../common/buttons/Button';
import { Element as ScrollElement } from 'react-scroll';

import './Whitepaper.css';

const btnStyle = {
    color: 'black',
};

export const Whitepaper = () => {
    return (
        <ScrollElement name='whitepaper'>
            <div>
                <div className='whitepaper-section row'>
                    <div className='whitepaper__text col'>
                        <h1>Read Our Whitepaper</h1>
                        <p>
                            Want to know all the details about our Boozemoon
                            token, safety features and futures plans? Check out
                            our&nbsp;
                            <a
                                href='https://boozemoon.finance/files/BoozeMoonWhitepaperFinal.pdf'
                                target='_blank'
                            >
                                whitepaper
                            </a>
                        </p>
                        <a
                            href='https://boozemoon.finance/files/BoozeMoonWhitepaperFinal.pdf'
                            target='_blank'
                        >
                            <Button btnText='Read Here' style={btnStyle} />
                        </a>

                        <p className='whitepaper__text__bottom'>
                            Our contract is audited before we even launched.
                            Check the detailed audit results{' '}
                            <a
                                href='https://boozemoon.finance/files/BoozeMoon-1.pdf'
                                target='_blank'
                            >
                                here
                            </a>{' '}
                            .
                        </p>
                    </div>
                    <div className='whitepaper__img col d-none d-sm-block'>
                        <img src={whitepaper} alt='whitepaper' />
                    </div>
                </div>
                <div className='howTo-section row'>
                    <div className='howTo__img col d-none d-sm-block'></div>
                    <div className='howTo__text col'>
                        <h1>How to Buy</h1>
                        <p>
                            Our contract is audited before we even launched.
                            Check the detailed audit results&nbsp;
                            <a
                                href='https://boozemoon.finance/files/BoozeMoon-1.pdf'
                                target='_blank'
                            >
                                here
                            </a>
                            .
                        </p>

                        <a
                            href='https://pancakeswap.finance/swap?outputCurrency=0x36b50a5ac2b90c7652d4ac17181aa63b7ceb2fea&inputCurrency=BNB'
                            target='_blank'
                        >
                            <Button btnText='Buy Today' style={btnStyle} />
                        </a>
                    </div>
                </div>
            </div>
        </ScrollElement>
    );
};
