import React from 'react';
import mainLogo from '../../assets/img/main_logo.png';
import {
    faTwitter,
    faTelegramPlane,
    faGgCircle,
} from '@fortawesome/free-brands-svg-icons';
import { faPoo, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';

export const Footer = () => {
    return (
        <div className='container'>
            <img
                className='footer__img'
                src={mainLogo}
                alt='footer-logo'
                height='240'
            />
            <div className='footer__social'>
                <a
                    href='https://t.me/boozemoon'
                    className='social-link__footer'
                >
                    <FontAwesomeIcon
                        icon={faTelegramPlane}
                        className='social-icon_footer'
                    />
                </a>
                <a
                    href='https://twitter.com/BoozeMoon'
                    className='social-link__footer'
                >
                    <FontAwesomeIcon
                        icon={faTwitter}
                        className='social-icon_footer'
                    />
                </a>
                <a
                    href='https://poocoin.app/tokens/0x36b50a5ac2b90c7652d4ac17181aa63b7ceb2fea'
                    className='social-link__footer'
                >
                    <FontAwesomeIcon
                        className='social-icon_footer'
                        icon={faPoo}
                    />
                </a>
                <a
                    href='https://bscscan.com/token/0x36b50a5ac2b90c7652d4ac17181aa63b7ceb2fea'
                    className='social-link__footer'
                >
                    <FontAwesomeIcon
                        icon={faChartLine}
                        className='social-icon_footer'
                    />
                </a>
            </div>
            <p>Your one and only beer token</p>
        </div>
    );
};
