import React from 'react';
import './Button.css';

export const Button = ({ btnText, style }) => {
    return (
        <button type='button' className='btn primary-btn' style={style}>
            {btnText}
        </button>
    );
};
