import React from 'react';
import { Element as ScrollElement } from 'react-scroll';
import './Timezone.css';

export const Timezone = () => {
    return (
        <ScrollElement name='roadmap'>
            <div className='container'>
                <div className='timezone-section__header'>
                    <h1>BoozeMoon Roadmap</h1>
                    <p>
                        Our project development just started and yet we have
                        achieved numerous milestones. Our team is working hard
                        to keep BoozeMoon on the verge of innovation
                    </p>
                </div>

                <div className='timezone-section__milestones row col-12'>
                    <div className='timezone-section__img col d-none d-sm-block'></div>
                    <ul className='timezone-section__milestones-text col'>
                        <li className='timezone-section__milestones-item'>
                            <div
                                className='timeline-item-title'
                                style={{ marginRight: 21 }}
                            >
                                <p>May 2021</p>
                            </div>
                            <div className='timeline-item-content'>
                                <h4
                                    className='timezone-section__title'
                                    style={{ paddingLeft: 30 }}
                                >
                                    &nbsp;
                                </h4>
                                <p
                                    className='timezone-section__description'
                                    style={{ paddingLeft: 30 }}
                                >
                                    &#9989; Audit the smart contact. <br />
                                    &#9989; Launch Presell on DxSale. <br />
                                    &#9989; Launch Token on Pancakeswap. <br />
                                    &#9989; Launch Twitter and Reddit campaign.
                                    <br />
                                    &#128993; Launch Poocoin Campaign. <br />
                                    &#128993; Coingecko Application. <br />
                                    &#128993; Blockfolio Application <br />
                                </p>
                            </div>
                        </li>

                        <li className='timezone-section__milestones-item'>
                            <div
                                className='timeline-item-title'
                                style={{ marginRight: 15 }}
                            >
                                <p>June 2021</p>
                            </div>
                            <div className='timeline-item-content'>
                                <h4
                                    className='timezone-section__title'
                                    style={{ paddingLeft: 30 }}
                                >
                                    &nbsp;
                                </h4>
                                <p
                                    className='timezone-section__description'
                                    style={{ paddingLeft: 30 }}
                                >
                                    Launch Merch Store <br />
                                    Develop branding for our very own crypto
                                    beer <br />
                                    Community voting features <br />
                                </p>
                            </div>
                        </li>

                        <li className='timezone-section__milestones-item'>
                            <div
                                className='timeline-item-title'
                                style={{ marginRight: 30 }}
                            >
                                <p>Q3 2021</p>
                            </div>
                            <div className='timeline-item-content'>
                                <h4
                                    className='timezone-section__title'
                                    style={{ paddingLeft: 30 }}
                                >
                                    &nbsp;
                                </h4>
                                <p
                                    className='timezone-section__description'
                                    style={{ paddingLeft: 30 }}
                                >
                                    Start production of our BoozeMoon beer with
                                    our partner brewery <br />
                                    Launching beer NFTs <br />
                                    Launching the BoozeMoon online shop <br />
                                </p>
                            </div>
                        </li>

                        <li className='timezone-section__milestones-item'>
                            <div
                                className='timeline-item-title'
                                style={{ marginRight: 32 }}
                            >
                                <p>Q4 2021</p>
                            </div>
                            <div className='timeline-item-content'>
                                <h4
                                    className='timezone-section__title'
                                    style={{ paddingLeft: 30 }}
                                >
                                    &nbsp;
                                </h4>
                                <p
                                    className='timezone-section__description last-item'
                                    style={{
                                        paddingLeft: 30,
                                        borderLeft: 'none',
                                    }}
                                >
                                    Adding more inventory to the store <br />
                                    Making the BOOZE coin payment option <br />
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </ScrollElement>
    );
};
