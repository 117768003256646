import React from 'react';
import { Element as ScrollElement } from 'react-scroll';
import {
    faTwitter,
    faTelegramPlane,
    faGgCircle,
} from '@fortawesome/free-brands-svg-icons';
import { faPoo, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Heading.css';

export const Heading = () => {
    return (
        <ScrollElement name='home'>
            <div className='heading-section'>
                <p className='heading-title'>Your one and only beer token</p>
                <a href='https://t.me/boozemoon' className='social-link'>
                    <FontAwesomeIcon
                        icon={faTelegramPlane}
                        className='social-icon'
                    />
                </a>
                <a href='https://twitter.com/BoozeMoon' className='social-link'>
                    <FontAwesomeIcon icon={faTwitter} className='social-icon' />
                </a>
                <a
                    href='https://poocoin.app/tokens/0x36b50a5ac2b90c7652d4ac17181aa63b7ceb2fea'
                    className='social-link'
                >
                    <FontAwesomeIcon className='social-icon' icon={faPoo} />
                </a>
                <a
                    href='https://bscscan.com/token/0x36b50a5ac2b90c7652d4ac17181aa63b7ceb2fea'
                    className='social-link'
                >
                    <FontAwesomeIcon
                        icon={faChartLine}
                        className='social-icon'
                    />
                </a>
            </div>
        </ScrollElement>
    );
};
